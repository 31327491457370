/* eslint-disable @typescript-eslint/naming-convention */
export enum AnalyticsEvents {
  CONTACT_ADD = 'feature_contact_add',
  CONTACT_DELETE = 'feature_contact_delete',
  CONTACT_IMPORT = 'feature_contact_import',
  CONTACT_UPDATE = 'feature_contact_update',
  EMERGENCY_CLEARED = 'emergency_cleared',
  EMERGENCY_OCCURRED = 'emergency_occurred',
  GUARD_MODE_ACTIVATED = 'feature_guard_mode_activated',
  MAP_OPEN = 'map_open',
  PURCHASE_CREDITS = 'feature_purchase_credits',
  SCREEN_VIEW = 'screen_view',
  URL_OPEN = 'url_open',
  USER_LOGIN_APPLE = 'login_apple',
  USER_LOGIN_EMAIL = 'login_email',
  USER_LOGIN_GOOGLE = 'login_google',
  USER_SIGNUP = 'sign_up',
  USER_PROFILE_DELETE = 'feature_user_profile_delete',
  USER_PROFILE_UPDATE = 'feature_user_profile_update',
  USER_PASSWORT_RESET = 'feature_user_password_reset'
}
