import { AnalyticsEvent } from '@interfaces/analytics-event.interface';
import { AnalyticsEvents } from '@enums/analytics-events.enum';
import { AppConstants } from '@app/app.constants';
import { AppTrackingTransparency } from 'capacitor-plugin-app-tracking-transparency';
import { Capacitor } from '@capacitor/core';
import { DeviceNotification } from '@models/device';
import { DeviceNotificationsService } from '@services/device-notifications/device-notifications.service';
import { DialogService } from '@services/dialog/dialog.service';
import { Event, NavigationEnd, Router } from '@angular/router';
import { FirebaseAnalytics } from '@capacitor-firebase/analytics';
import { GuardMode } from '@app/interfaces/guard-mode.interface';
import { GuardModeService } from '../guard-mode/guard-mode.service';
import { Injectable, inject } from '@angular/core';
import { NotificationType } from '@app/enums/notification-type.enum';
import { StorageService } from '../storage/storage.service';
import { Subject } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { debounceTime, filter, map, takeUntil } from 'rxjs/operators';
import { environment } from '@environment/environment';

@Injectable({
  providedIn: 'root'
})
export class AnalyticsService {
  private hasConfirmed = false;
  private readonly dialogService: DialogService = inject(DialogService);
  private readonly guardModeService: GuardModeService = inject(GuardModeService);
  private readonly deviceNotificationsService: DeviceNotificationsService = inject(DeviceNotificationsService);
  private readonly router: Router = inject(Router);
  private readonly storageService: StorageService = inject(StorageService);
  private readonly translate: TranslateService = inject(TranslateService);
  private readonly unsubscribe$: Subject<void> = new Subject();

  public async initialize(): Promise<void> {
    await this.initTracking(Boolean(localStorage.getItem(AppConstants.GA_CONSENT)));
  }

  /*
   * Public method to set user property for analytics
   */
  public async setUserProperty(key: string, value: string): Promise<void> {
    if (environment.production && this.hasConfirmed) {
      await FirebaseAnalytics.setUserProperty({ key, value });
    }
  }

  /*
   * Public method to log custom event
   */
  public async logEvent(event: AnalyticsEvent): Promise<void> {
    if (environment.production && this.hasConfirmed) {
      await FirebaseAnalytics.logEvent(event);
    }
  }

  /*
   * Public method to show confirm dialog for user to give consent
   */
  public async requestPermission(): Promise<void> {
    let result = false;
    if (Capacitor.getPlatform() === 'ios') {
      let { status } = await AppTrackingTransparency.getStatus();
      if (status !== 'authorized') {
        const response = await AppTrackingTransparency.requestPermission();
        status = response.status;
      }
      result = status === 'authorized' ? true : false;
    } else {
      result = await this.dialogService.showConfirm({
        title: this.translate.instant('dialog_analytics_consent_title'),
        message: this.translate.instant('dialog_analytics_consent_message'),
        okButtonTitle: this.translate.instant('dialog_analytics_consent_ok'),
        cancelButtonTitle: this.translate.instant('dialog_analytics_consent_cancel')
      });
    }
    this.initTracking(result);
  }

  /*
   * Public method to remove permission
   */
  public async removePermission(): Promise<void> {
    await FirebaseAnalytics.setEnabled({
      enabled: false
    });
    await this.storageService.set(AppConstants.GA_CONSENT, false);
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  /*
   * Public method to check if user has given consent
   */
  public async checkPermissions(): Promise<boolean> {
    if (Capacitor.getPlatform() === 'ios') {
      const { status } = await AppTrackingTransparency.getStatus();
      return status === 'authorized' ? true : false;
    } else {
      return await this.storageService.get(AppConstants.GA_CONSENT);
    }
  }

  /*
   * Private method use to init web tracking and router subscription
   */
  private async initTracking(userHasConfirmed: boolean): Promise<void> {
    // set current settings to local storage
    await this.storageService.set(AppConstants.GA_CONSENT, userHasConfirmed);
    this.hasConfirmed = userHasConfirmed;

    if (environment.production && userHasConfirmed) {
      await FirebaseAnalytics.setEnabled({
        enabled: userHasConfirmed
      });

      // enable screen view tracking
      this.router.events
        .pipe(filter((e: Event) => e instanceof NavigationEnd))
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe(async (event: Event) => {
          await FirebaseAnalytics.logEvent({
            name: AnalyticsEvents.SCREEN_VIEW,
            params: {
              screen: (event as NavigationEnd).url
            }
          });
        });

      await this.addListener();
    }
  }

  /*
   * Private method use to init listener for guard mode and emergency notifications
   */
  private async addListener(): Promise<void> {
    this.guardModeService.isActive
      .pipe(
        filter((guardMode: GuardMode) => guardMode !== undefined && guardMode.isActive),
        debounceTime(3000)
      )
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(async (guardMode: GuardMode) => {
        if (guardMode.isActive) {
          await this.logEvent({
            name: AnalyticsEvents.GUARD_MODE_ACTIVATED
          });
        }
      });

    this.deviceNotificationsService.notifications
      .pipe(
        filter((notification: DeviceNotification | null): notification is DeviceNotification => notification !== null),
        map(notification => notification as DeviceNotification),
        filter((notification: DeviceNotification) => notification.type === NotificationType.DEVICE_EMERGENCY),
        debounceTime(3000)
      )
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(async (notification: DeviceNotification) => {
        await this.logEvent({
          name: AnalyticsEvents.EMERGENCY_OCCURRED,
          params: {
            deviceId: notification.deviceId
          }
        });
      });
  }
}
